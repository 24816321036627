<template>
  <section style="margin: 0 15px">
    <div class="searchBox performance">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="按组织分析" name="first"></el-tab-pane>
        <el-tab-pane label="同期分析" name="second"></el-tab-pane>
      </el-tabs>
      <el-form
        :model="filter"
        ref="filter"
        style="background-color: #fff"
        label-width="60px"
      >
        <el-row>
          <div class="clearfix">
            <el-form-item
              v-if="activeName == 'first'"
              label="时间："
              prop="time"
              class="fl"
            >
              <el-date-picker
                v-model="filter.time"
                :clearable="false"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="loadAllChart"
                style="width: 240px"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              v-if="activeName == 'second'"
              label="时间："
              prop="time"
              class="fl"
              style="margin-bottom: 20px"
            >
              <div style="display: flex">
                <el-select
                  v-model="msgInfo.timeType"
                  placeholder="请选择"
                  filterable
                  @change="changeType"
                  style="width: 80px"
                  class="dinput"
                >
                  <el-option
                    v-for="(item, index) in timeTypeList"
                    :key="'time_' + index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  v-show="msgInfo.timeType == '按月'"
                  v-model="filter.time"
                  type="monthrange"
                  @change="loadInfo"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px; padding: 2px 10px; margin-top: 2px"
                  class="dinputr"
                ></el-date-picker>
                <el-date-picker
                  v-model="filter.time"
                  v-show="
                    msgInfo.timeType == '按季' || msgInfo.timeType == '总计'
                  "
                  type="daterange"
                  @change="loadInfo"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px; padding: 2px 10px; margin-top: 2px"
                  class="dinputr"
                ></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item
              prop="searchIdList"
              label="对象："
              class="fl"
              style="margin-left: 20px"
            >
              <el-select
                v-model="filter.searchType"
                placeholder="请选择大类"
                filterable
                @change="changeOption"
                style="width: 90px"
                class="dinput"
              >
                <el-option
                  v-for="item in optionsList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <el-cascader
                v-if="activeName == 'first'"
                class="searchCascader dinputr"
                style="width: 250px"
                filterable
                clearable
                :options="options2"
                collapse-tags
                :show-all-levels="false"
                v-model="searchIdList"
                :props="props"
                placeholder="请选择小类"
                ref="dataRef"
                @change="getSearch"
                :filter-method="filterFun"
              >
              </el-cascader>
              <el-cascader
                v-else
                class="searchCascader dinputr"
                style="width: 250px"
                filterable
                clearable
                :options="options2"
                collapse-tags
                :show-all-levels="false"
                v-model="searchIdList1"
                :props="props"
                placeholder="请选择小类"
                ref="dataRef"
                @change="getSearch"
                :filter-method="filterFun"
              >
              </el-cascader>
              <el-checkbox
                class="showCheckbox"
                style="width: 100px; margin-left: 15px"
                v-model="filter.show"
                @change="showAccount"
                >停用账户</el-checkbox
              >
            </el-form-item>
          </div>
        </el-row>
      </el-form>
    </div>
    <div v-if="activeName == 'first'">
      <div class="analyses">
        <div id="echarts1"></div>
      </div>
      <ul class="barEcharts clearfix" style="padding-bottom: 15px">
        <li>
          <div class="bar" id="company"></div>
          <div style="right: 5%; left: 5%; position: absolute; top: 5%">
            <el-select
              v-model="filter.companyRankType"
              style="width: 130px; margin-left: 85px; margin-top: -15px"
              placeholder="请选择"
              @change="loadCompanyChart"
            >
              <el-option
                v-for="item in companyRankTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="more" @click="showMore('company')" style="right: 0"
              >更多 &gt</span
            >
          </div>
        </li>
        <li>
          <div class="bar" id="achieve"></div>
          <span class="more" @click="showMore('achieve')">更多 &gt</span>
        </li>
        <li>
          <div class="bar" id="offer"></div>
          <span class="more" @click="showMore('offer')">更多 &gt</span>
        </li>
        <li>
          <div class="bar" id="invoice"></div>
          <span class="more" @click="showMore('invoice')">更多 &gt</span>
        </li>
      </ul>
    </div>
    <div
      v-if="activeName == 'second'"
      class="analyses"
      style="position: relative; min-height: 500px"
    >
      <el-radio-group
        class="moreBtn"
        v-model="msgInfo.companyRankType"
        size="mini"
        @change="averageChange"
      >
        <el-radio-button label="OFFER_PERFORMANCE">offer业绩</el-radio-button>
        <el-radio-button label="INVOICE_PERFORMANCE">开票业绩</el-radio-button>
        <el-radio-button label="ACCOUNT_PERFORMANCE">回款业绩</el-radio-button>
      </el-radio-group>
      <div
        v-for="(id, index) in sourceIds"
        :key="index"
        class="conentChart"
        :style="{ width: '100%', margin: '20px auto 0' }"
        :ref="'myEchart' + index"
        :id="'myEchart' + index"
      ></div>
    </div>
    <el-drawer
      :visible.sync="dialogVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        {{ titleName }}
      </div>
      <div class="drawerCon">
        <el-row>
          <el-col>
            <el-button size="mini" @click="downExp" :loading="btnLoading"
              >导出</el-button
            >
            <el-pagination
              :hide-on-single-page="pagination.total > 10 ? false : true"
              v-if="pagination.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="pagination.pageSize"
              layout="total,prev, pager, next,sizes"
              :total="pagination.total"
              style="padding-right: 0px"
            ></el-pagination>
          </el-col>
          <el-col>
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              :data="ListData"
              class="tabBorder custor is-top"
            >
              <el-table-column v-if="exportType == 'company'" label="客户名称">
                <template #default="scope">
                  <span class="tabHref" @click="toCustomer(scope.row.id)">{{
                    scope.row.name
                  }}</span>
                </template></el-table-column
              >
              <el-table-column
                v-if="exportType !== 'company'"
                label="顾问"
                prop="name"
              ></el-table-column>
              <el-table-column
                label="目标业绩"
                prop="targetString"
                v-if="exportType == 'achieve'"
              ></el-table-column>
              <el-table-column
                :label="
                  exportType == 'achieve'
                    ? '达成业绩'
                    : exportType == 'offer'
                    ? 'Offer业绩额'
                    : exportType == 'company'
                    ? '业绩金额'
                    : '开票业绩额'
                "
                prop="achievement"
              >
                <template slot-scope="scope">
                  <span
                    class="tabHref"
                    @click="showInfo(scope.row)"
                    v-if="scope.row.achievement"
                    >{{ scope.row.achievement }}</span
                  >
                  <span v-else>-</span>
                </template></el-table-column
              >
              <el-table-column label="达成率" v-if="exportType == 'achieve'">
                <template slot-scope="scope">
                  {{ scope.row.completeRate }}
                </template>
              </el-table-column>
              <el-table-column
                v-if="exportType !== 'company'"
                label="排行"
                prop="order"
              ></el-table-column>
              <el-table-column
                v-if="exportType == 'company'"
                label="业绩占比"
                prop="percent"
              >
                <template slot-scope="scope">
                  {{ scope.row.percent }}%
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :hide-on-single-page="pagination.total > 10 ? false : true"
              v-if="pagination.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="pagination.pageSize"
              layout="total,prev, pager, next,sizes"
              :total="pagination.total"
              style="padding-right: 0px"
            ></el-pagination>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="dialogInfoVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      destroy-on-close
      :before-close="handleClose1"
    >
      <div slot="title" class="drawerTitle">
        {{ InfotitleName }}
      </div>
      <div class="drawerCon">
        <performanceInfo
          :chartType="exportType"
          :emplyeeId="emplyeeId"
          :filter="filter"
          :sourceIds="sourceIds"
          :real="real"
          :companyRankType="filter.companyRankType"
        ></performanceInfo>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="customerDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>客户
      </div>
      <div class="drawerCon">
        <customer :customId="customId" :activeToInfo="activedToInfo"></customer>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import $ from "jquery";
import {
  permissionType,
  permissionTree,
  getCompleteRate,
  getInvoiceRate,
  getOfferRate,
  exportCompleteRate,
  exportInvoicerRate,
  exportOfferRate,
  achievementCompleteRate,
  permissionUserIds,
  analyzeCompanyRank, //客户到岗业绩排行
  analyzeExportCompanyRank, //导出客户到岗业绩排行
  analyzeAchievementYOY, //同期分析
} from "../../api/api";
import * as echarts from "echarts";
import { ApiBaseUrl } from "../../api/http";
import performanceInfo from "../../components/report/performanceInfo";
import customer from "./../../components/customer/customerInfo";
export default {
  components: { performanceInfo, customer },
  data() {
    return {
      titleName: "",
      InfotitleName: "",
      dialogVisible: false,
      dialogInfoVisible: false, //详情
      btnLoading: false,
      filter: {
        companyRankType: "ENTRY_PERFORMANCE",
        //查询条件
        time: [
          new Date().getFullYear() + "-01" + "-01",
          new Date(Date.now() - 24 * 60 * 60 * 1000).Format("yyyy-MM") + "-01",
        ],
        searchType: "顾问",
        show: false, // 默认不展示停用数据
      },
      userId: parseInt(this.getStore("uid")),
      baseUrl: ApiBaseUrl,
      optionsList: [],
      searchIdList: [], //选中的小类
      searchIdList1: [],
      sourceIds: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      options2: [],
      activeName: "first",
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      CompleteData: [],
      CompleteXData: [],
      CompleteYData: [],
      OfferData: [],
      OfferXData: [],
      OfferYData: [],
      InvoiceData: [],
      InvoiceYData: [],
      InvoiceXData: [],
      ListData: [],
      searchData: {},
      exportType: "",
      InfoData: [],
      emplyeeId: null,
      MyParm: {},
      MyParm1: {},
      MyParm2: {},
      MyParm3: {},
      MyParm4: {},
      CompanyRankData: [],
      CompanyRankXData: [],
      CompanyRankYData: [],
      real: false,
      activedToInfo: "1",
      customerDrawerVisible: false,
      customId: "",
      companyRankTypeList: [
        {
          value: "OFFER_PERFORMANCE",
          label: "Offer业绩Top5",
        },
        {
          value: "ENTRY_PERFORMANCE",
          label: "到岗业绩Top5",
        },
        {
          value: "INVOICE_PERFORMANCE",
          label: "开票业绩Top5",
        },
        {
          value: "ACCOUNT_PERFORMANCE",
          label: "回款业绩Top5",
        },
      ],
      msgInfo: {
        companyRankType: "OFFER_PERFORMANCE",
        endDate: "",
        show: "",
        sourceIds: [],
        sourceType: "",
        startDate: "",
        timeType: "按月",
      },
      timeTypeList: ["总计", "按季", "按月"],
    };
  },
  watch: {
    dialogVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    dialogInfoVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  created() {
    this.permissionType();
    this.loadpermissionUserIds();
  },
  methods: {
    showInfo(row, isTrue) {
      this.emplyeeId = row.id;
      if (isTrue == "业绩分析") {
        this.real = true;
      } else {
        this.real = false;
      }
      if (this.exportType == "offer") {
        this.InfotitleName = "Offer业绩详情";
      } else if (this.exportType == "daogang") {
        this.InfotitleName = "到岗业绩详情";
      } else if (this.exportType == "invoice") {
        this.InfotitleName = "开票业绩详情";
      } else if (this.exportType == "account") {
        this.InfotitleName = "回款业绩详情";
      } else {
        let type = null;
        if (this.filter.companyRankType == "OFFER_PERFORMANCE") {
          type = "Offer业绩";
        } else if (this.filter.companyRankType == "ENTRY_PERFORMANCE") {
          type = "到岗业绩";
        } else if (this.filter.companyRankType == "INVOICE_PERFORMANCE") {
          type = "开票业绩";
        } else if (this.filter.companyRankType == "ACCOUNT_PERFORMANCE") {
          type = "回款业绩";
        }
        this.InfotitleName = "客户" +
          type +
          "详情（" +
          this.filter.time[0].substring(0, 7) +
          "至" +
          this.filter.time[1].substring(0, 7) +
          " " +
          "）";
      }

      this.dialogInfoVisible = true;
    },
    loadAllChart() {
      this.searchData = {
        companyRankType: this.filter.companyRankType,
        endDate: this.filter.time[1],
        show: this.filter.show,
        sourceIds: this.sourceIds,
        sourceType: this.filter.searchType,
        startDate: this.filter.time[0],
      };
      if (this.sourceIds.length == 0) {
        this.$message.error("请选择分析对象！");
        return false;
      }
      this.loadCompanyRank();
      this.loadCompleteRate();
      this.loadOfferRate();
      this.loadInvoiceRate();
      this.loadachievementCompleteRate();
    },
    //
    loadCompanyChart() {
      this.searchData = {
        companyRankType: this.filter.companyRankType,
        endDate: this.filter.time[1],
        show: this.filter.show,
        sourceIds: this.sourceIds,
        sourceType: this.filter.searchType,
        startDate: this.filter.time[0],
      };
      if (this.sourceIds.length == 0) {
        this.$message.error("请选择分析对象！");
        return false;
      }
      this.loadCompanyRank();
    },
    loadachievementCompleteRate() {
      achievementCompleteRate(this.searchData).then((res) => {
        if (res.success) {
          let target = []; //	业绩目标
          let accountAchievement = []; //	总回款
          let accountDifference = []; //	总回款差额
          let completeRate = []; //	达成率
          let entryAchievement = []; //	到岗业绩
          let entryDifference = []; //	到岗业绩差额
          let invoiceAchievement = []; //	开票业绩
          let invoiceDifference = []; //	开票业绩差额
          let offerAchievement = []; //	offer业绩
          let offerDifference = []; //	Offer业绩差额
          let xData = [];
          res.result.forEach((item) => {
            target.push(item.target);
            accountAchievement.push(item.accountAchievement);
            accountDifference.push(item.accountDifference);
            completeRate.push(item.completeRate);
            entryAchievement.push(item.entryAchievement);
            entryDifference.push(item.entryDifference);
            invoiceAchievement.push(item.invoiceAchievement);
            invoiceDifference.push(item.invoiceDifference);
            offerAchievement.push(item.offerAchievement);
            offerDifference.push(item.offerDifference);
            xData.push(item.departmentName);
            $.data(this.MyParm, item.departmentName, item.departmentId);
          });
          setTimeout(() => {
            this.drawChart(
              xData,
              target,
              offerAchievement,
              entryAchievement,
              invoiceAchievement,
              accountAchievement,
              completeRate,
              offerDifference,
              entryDifference,
              invoiceDifference,
              accountDifference
            );
          }, 500);
        }
      });
    },
    //获取客户到岗业绩排行
    loadCompanyRank() {
      analyzeCompanyRank(this.searchData).then((res) => {
        if (res.success) {
          let data = res.result
            ? res.result.filter((item) => {
                return item.achievement > -1;
              })
            : [];
          this.CompanyRankData = res.result;
          this.CompanyRankXData = [];
          this.CompanyRankYData = [];
          data.forEach((item) => {
            $.data(this.MyParm4, item.name, item.id);
          });
          if (data.length > 5) {
            data = data.slice(0, 5);
            data.forEach((item) => {
              this.CompanyRankXData.push(item.achievement);
              this.CompanyRankYData.push(item.name);
            });
          } else {
            data.forEach((item) => {
              this.CompanyRankXData.push(item.achievement);
              this.CompanyRankYData.push(item.name);
            });
          }
          let type = null;
          if (this.filter.companyRankType == "OFFER_PERFORMANCE") {
            type = "Offer业绩";
          } else if (this.filter.companyRankType == "ENTRY_PERFORMANCE") {
            type = "到岗业绩";
          } else if (this.filter.companyRankType == "INVOICE_PERFORMANCE") {
            type = "开票业绩";
          } else if (this.filter.companyRankType == "ACCOUNT_PERFORMANCE") {
            type = "回款业绩";
          }
          setTimeout(() => {
            this.drawBarChart(
              "company",
              "客户业绩",
              "{value}",
              this.CompanyRankYData,
              this.CompanyRankXData[0] + 200,
              type,
              this.CompanyRankXData
            );
          }, 500);
        }
      });
    },
    loadCompleteRate() {
      //获取达成率数据
      getCompleteRate(this.searchData).then((res) => {
        if (res.success) {
          let data = res.result.filter((item) => {
            return item.rate > -1;
          });
          this.CompleteData = res.result;
          this.CompleteXData = [];
          this.CompleteYData = [];
          data.forEach((item) => {
            $.data(this.MyParm1, item.name, item.id);
          });
          if (data.length > 5) {
            data = data.slice(0, 5);
            data.forEach((item) => {
              this.CompleteXData.push(item.rate);
              this.CompleteYData.push(item.name);
            });
          } else {
            data.forEach((item) => {
              this.CompleteXData.push(item.rate);
              this.CompleteYData.push(item.name);
            });
          }
          setTimeout(() => {
            this.drawBarChart(
              "achieve",
              "达成率排行",
              "{value} %",
              this.CompleteYData,
              100,
              "达成率",
              this.CompleteXData
            );
          }, 500);
        }
      });
    },
    loadOfferRate() {
      //获取offer业绩数据
      getOfferRate(this.searchData).then((res) => {
        if (res.success) {
          let data = res.result;
          this.OfferData = res.result;
          this.OfferXData = [];
          this.OfferYData = [];
          data.forEach((item) => {
            $.data(this.MyParm2, item.name, item.id);
          });
          if (data.length > 5) {
            data = data.slice(0, 5);
            data.forEach((item) => {
              this.OfferXData.push(item.achievement);
              this.OfferYData.push(item.name);
            });
          } else {
            data.forEach((item) => {
              this.OfferXData.push(item.achievement);
              this.OfferYData.push(item.name);
            });
          }
          setTimeout(() => {
            this.drawBarChart(
              "offer",
              "Offer业绩Top" + this.OfferYData.length,
              "{value}",
              this.OfferYData,
              this.OfferXData[0] + 200,
              "Offer业绩",
              this.OfferXData
            );
          }, 500);
        }
      });
    },
    loadInvoiceRate() {
      //获取开票业绩数据
      getInvoiceRate(this.searchData).then((res) => {
        if (res.success) {
          let data = res.result;
          this.InvoiceData = res.result;
          this.InvoiceXData = [];
          this.InvoiceYData = [];
          data.forEach((item) => {
            $.data(this.MyParm3, item.name, item.id);
          });
          if (data.length > 5) {
            data = data.slice(0, 5);
            data.forEach((item) => {
              this.InvoiceXData.push(item.achievement);
              this.InvoiceYData.push(item.name);
            });
          } else {
            data.forEach((item) => {
              this.InvoiceXData.push(item.achievement);
              this.InvoiceYData.push(item.name);
            });
          }
          setTimeout(() => {
            this.drawBarChart(
              "invoice",
              "开票业绩Top" + this.InvoiceYData.length,
              "{value}",
              this.InvoiceYData,
              this.InvoiceXData[0] + 200,
              "开票业绩",
              this.InvoiceXData
            );
          }, 500);
        }
      });
    },
    showAccount() {
      this.permissionTree(this.filter.searchType);
      this.loadAllChart();
    },
    loadpermissionUserIds() {
      permissionUserIds().then((res) => {
        if (res.success) {
          this.sourceIds = res.result;
          this.permissionTree("顾问", true);
          if (this.activeName == "first") {
            this.loadAllChart();
          } else {
            this.loadInfo();
          }
        }
      });
    },
    // 大类
    permissionType() {
      permissionType().then((res) => {
        if (res.success) {
          this.optionsList = res.result;
        }
      });
    },
    // 小类
    permissionTree(val, isfirst, isGetSearch) {
      permissionTree({ type: val, showDisableUser: this.filter.show }).then(
        (res) => {
          if (res.success) {
            this.options2 = res.result;
            // 如果是初次加载页面数据，在options2中查找userId对应的searchIdList
            if (isfirst) {
              this.searchIdList = [];
              this.searchIdList1 = [];
              this.sourceIds.forEach((item) => {
                this.searchIdList.push(
                  this.changeDetSelect(item, this.options2)
                );
              });
              if (this.msgInfo.timeType == "总计") {
                this.searchIdList1 = this.searchIdList;
              } else {
                this.searchIdList1.push(
                  this.changeDetSelect(
                    parseInt(this.getStore("uid")),
                    this.options2
                  )
                );
                if (isGetSearch) {
                  this.getSearch();
                }
              }
            }
          }
        }
      );
    },
    //回显(多级)
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (
              childrenData[j].children &&
              childrenData[j].children.length > 0
            ) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(treeData, depth);
    },
    changeOption(val) {
      //
      if (this.activeName == "first") {
        this.searchIdList = [];
      } else {
        this.searchIdList1 = [];
        this.sourceIds = [];
      }
      this.pagination.pageNo = 1;
      if (val) {
        this.permissionTree(val);
      }
    },
    getSearch(val) {
      if (this.activeName == "first") {
        this.sourceIds = this.searchIdList.map((i) => {
          return i[i.length - 1];
        });
        this.loadAllChart();
      } else if (this.activeName == "second") {
        this.sourceIds = this.searchIdList1.map((i) => {
          return i[i.length - 1];
        });
        this.loadInfo();
      }
    },
    drawChart(
      Xdata,
      targetData,
      offerData,
      entryData,
      invoiceData,
      accountData,
      completeData,
      offerDifData,
      entryDifData,
      invoiceDifData,
      accountDifData
    ) {
      let chartDom = document.getElementById("echarts1");
      let myChart = echarts.init(chartDom);
      let option;
      let that = this;
      let colors = [
        "#8da7f2",
        "#b589eb",
        "#92d671",
        "#f2c561",
        "#e86666",
        "#b7bee2",
      ];
      option = {
        color: colors,
        title: {
          text: "业绩分析",
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
          formatter: function (params) {
            let str = params[0].name;
            params.forEach((item) => {
              if (
                item.seriesName !== "差额_Offer业绩" &&
                item.seriesName !== "差额_到岗业绩" &&
                item.seriesName !== "差额_回款业绩"
              ) {
                str =
                  str +
                  "<br/>" +
                  item.marker +
                  item.seriesName +
                  " : " +
                  item.value;
              }
            });
            return str;
          },
        },
        toolbox: {
          show: true,
          right: "15px",
          feature: {
            saveAsImage: {
              show: true,
              title: "保存",
              type: "png",
              lang: ["点击保存"],
            },
          },
          iconStyle: {
            borderColor: "#999999", //图形的描边颜色
          },
          emphasis: {
            //hover样式
            iconStyle: {
              borderColor: "#d13034",
              color: "#d13034",
            },
          },
        },
        grid: {
          right: "5%",
          top: "20%",
          left: "90",
          bottom: "15%",
        },
        legend: {
          data: [
            "业绩目标",
            "Offer业绩",
            "到岗业绩",
            "开票业绩",
            "回款业绩",
            "达成率",
          ],
          itemWidth: 20,
          itemHeight: 12,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
            },
            data: Xdata,
          },
        ],
        dataZoom: [
          {
            show: true,
            height: 16,
            left: 100,
            right: 100,
            xAxisIndex: [0],
            bottom: 25,
            start: 0,
            end: 10,
            handleSize: "110%",
            handleStyle: {
              color: "#eeeeee",
            },
            textStyle: {
              color: "#333333",
            },
            fillerColor: "#dddddd",
            borderColor: "#eeeeee",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            left: 100,
            right: 100,
            start: 0,
            end: 30,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "金额（元）",
            min: 0,
            position: "left",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "全年达成率(%)",
            min: 0,
            position: "right",
            offset: 0,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: [
          {
            name: "业绩目标",
            type: "bar",
            stack: "one",
            barWidth: "10",
            data: targetData,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "Offer业绩",
            type: "bar",
            stack: "two",
            barWidth: "10",
            data: offerData,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "差额_Offer业绩",
            type: "bar",
            itemStyle: {
              normal: {
                color: "rgba(237,167,167,0.3)",
                borderRadius: [2, 2, 0, 0],
              },
            },
            stack: "two",
            data: offerDifData,
          },
          {
            name: "到岗业绩",
            type: "bar",
            stack: "three",
            barWidth: "10",
            data: entryData,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "差额_到岗业绩",
            type: "bar",
            stack: "three",
            itemStyle: {
              normal: {
                color: "rgba(237,167,167,0.3)",
                borderRadius: [2, 2, 0, 0],
              },
            },
            data: entryDifData,
          },
          {
            name: "开票业绩",
            type: "bar",
            stack: "four",
            barWidth: "10",
            data: invoiceData,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "开票业绩_差额",
            type: "bar",
            stack: "four",
            itemStyle: {
              normal: {
                color: "rgba(237,167,167,0.3)",
                borderRadius: [2, 2, 0, 0],
              },
            },
            data: invoiceDifData,
          },
          {
            name: "回款业绩",
            type: "bar",
            barWidth: "10",
            stack: "five",
            data: accountData,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "差额_回款业绩",
            type: "bar",
            stack: "five",
            itemStyle: {
              normal: {
                color: "rgba(237,167,167,0.3)",
                borderRadius: [2, 2, 0, 0],
              },
            },
            data: accountDifData,
          },
          {
            name: "达成率",
            type: "line",
            yAxisIndex: 1,
            data: completeData,
            symbolSize: "5",
          },
        ],
      };
      if (Xdata.length < 100) {
        option.dataZoom[0].start = 0;
        option.dataZoom[0].end = 40;
      }
      if (Xdata.length < 50) {
        option.dataZoom[0].start = 0;
        option.dataZoom[0].end = 60;
      }
      if (Xdata.length < 20) {
        option.dataZoom[0].start = 0;
        option.dataZoom[0].end = 80;
      }
      if (Xdata.length < 8) {
        option.dataZoom[0].show = false;
        option.dataZoom[0].start = 0;
        option.dataZoom[0].end = 100;
        option.grid.bottom = "8%";
      }
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
      myChart.on("click", function (param) {
        let id = null;
        let key = param.name; //key唯一
        id = $.data(that.MyParm, key); //可以拿到id
        if (param.seriesIndex !== 0) {
          if (param.seriesIndex == 1 || param.seriesIndex == 2) {
            //Offer业绩
            that.exportType = "offer";
          } else if (param.seriesIndex == 3 || param.seriesIndex == 4) {
            //到岗业绩
            that.exportType = "daogang";
          } else if (param.seriesIndex == 5 || param.seriesIndex == 6) {
            //开票业绩
            that.exportType = "invoice";
          } else if (param.seriesIndex == 7 || param.seriesIndex == 8) {
            //回款业绩
            that.exportType = "account";
          }
          that.showInfo({ name: key, id: id }, "业绩分析");
        }
      });
    },
    drawBarChart(id, title, xFormat, ydata, max, sName, sData) {
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      let that = this;
      option = {
        title: {
          text: title,
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
          formatter: function (params) {
            let str = params[0].name;
            params.forEach((item) => {
              str =
                str +
                "<br/>" +
                item.marker +
                item.seriesName +
                " : " +
                item.value +
                (item.seriesName == "达成率" && item.value != 0 ? "%" : "");
            });
            return str;
          },
        },
        grid: {
          top: "20%",
          left: "15",
          right: "15",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            formatter: xFormat,
            rotate: "10",
          },
          axisLine: {
            show: false,
          },
          max: max,
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          inverse: true, //倒叙
          scale: false,
          data: ydata,
          axisTick: {
          },
          axisLabel: {
            interval: 0,
            fontSize: 12,
            width: 60,
            color: "#999",
            formatter: function (value) {
              if (value.length > 4) {
                return value.substring(0, 4) + "...";
              } else {
                return value;
              }
            },
          },
          nameTextStyle: {
            fontSize: 10,
            overflow: "truncate",
            width: 60,
          },
        },
        series: [
          {
            name: sName,
            type: "bar",
            barMinHeight: 5,
            barWidth: 10,
            itemStyle: {
              normal: {
                borderRadius: [0, 2, 2, 0],
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#f76a6a", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#fa9285", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#fdaa95", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            data: sData,
          },
        ],
      };
      myChart.on("click", function (param) {
        let id1 = null;
        let key = param.name; //key唯一
        if (param.seriesName == "开票业绩" && id !== "company") {
          id1 = $.data(that.MyParm3, key); //可以拿到id
          that.exportType = "invoice";
        } else if (param.seriesName == "达成率" && id !== "company") {
          id1 = $.data(that.MyParm1, key); //可以拿到id
          that.exportType = "achieve";
        } else if (param.seriesName == "Offer业绩" && id !== "company") {
          that.exportType = "offer";
          id1 = $.data(that.MyParm2, key); //可以拿到id
        } else if (param.seriesName == "到岗业绩" || id == "company") {
          that.exportType = "company";
          id1 = $.data(that.MyParm4, key); //可以拿到id
        }
        that.showInfo({ name: key, id: id1 });
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    showMore(type) {
      this.exportType = type;
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.ListData = [];
      switch (type) {
        case "company":
          let type = "";
          if (this.filter.companyRankType == "OFFER_PERFORMANCE") {
            type = "Offer业绩";
          } else if (this.filter.companyRankType == "ENTRY_PERFORMANCE") {
            type = "到岗业绩";
          } else if (this.filter.companyRankType == "INVOICE_PERFORMANCE") {
            type = "开票业绩";
          } else if (this.filter.companyRankType == "ACCOUNT_PERFORMANCE") {
            type = "回款业绩";
          }
          this.titleName =
            "客户" +
            type +
            "（" +
            this.filter.time[0].substring(0, 7) +
            "至" +
            this.filter.time[1].substring(0, 7) +
            "）";
          this.ListData = this.CompanyRankData
            ? this.CompanyRankData.slice(
                0,
                Number(this.pagination.pageNo * this.pagination.pageSize)
              )
            : this.CompanyRankData;
          this.pagination.total = this.CompanyRankData
            ? this.CompanyRankData.length
            : 0;
          break;
        case "achieve":
          this.titleName =
            "达成率排行（" +
            this.filter.time[0].substring(0, 7) +
            "至" +
            this.filter.time[1].substring(0, 7) +
            "）";
          this.ListData = this.CompleteData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          this.pagination.total = this.CompleteData.length;
          break;
        case "offer":
          this.titleName =
            "Offer业绩（" +
            this.filter.time[0].substring(0, 7) +
            "至" +
            this.filter.time[1].substring(0, 7) +
            "）";
          this.ListData = this.OfferData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          this.pagination.total = this.OfferData.length;
          break;
        case "invoice":
          this.titleName =
            "开票业绩（" +
            this.filter.time[0].substring(0, 7) +
            "至" +
            this.filter.time[1].substring(0, 7) +
            "）";
          this.ListData = this.InvoiceData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          this.pagination.total = this.InvoiceData.length;
          break;
      }
      this.dialogVisible = true;
    },
    downExp() {
      //导出
      this.btnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      switch (this.exportType) {
        case "company":
          analyzeExportCompanyRank(this.searchData).then((res) => {
            if (res.success) {
              if (!setData) {
                isconfirm = false;
                location.href = this.baseUrl + res.result;
              }
            }
            this.btnLoading = false;
          });
          break;
        case "achieve":
          exportCompleteRate(this.searchData).then((res) => {
            if (res.success) {
              if (!setData) {
                isconfirm = false;
                location.href = this.baseUrl + res.result;
              }
            }
            this.btnLoading = false;
          });
          break;
        case "offer":
          exportOfferRate(this.searchData).then((res) => {
            if (res.success) {
              if (!setData) {
                isconfirm = false;
                location.href = this.baseUrl + res.result;
              }
            }
            this.btnLoading = false;
          });
          break;
        case "invoice":
          exportInvoicerRate(this.searchData).then((res) => {
            if (res.success) {
              if (!setData) {
                isconfirm = false;
                location.href = this.baseUrl + res.result;
              }
            }
            this.btnLoading = false;
          });
          break;
      }
    },
    handleCurrentChange(val) {
      $(".el-drawer__body").scrollTop(0);
      this.pagination.pageNo = val;
      switch (this.exportType) {
        case "company":
          this.ListData = this.CompanyRankData.slice(
            Number((this.pagination.pageNo - 1) * this.pagination.pageSize),
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "achieve":
          this.ListData = this.CompleteData.slice(
            Number((this.pagination.pageNo - 1) * this.pagination.pageSize),
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "offer":
          this.ListData = this.OfferData.slice(
            Number((this.pagination.pageNo - 1) * this.pagination.pageSize),
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "invoice":
          this.ListData = this.InvoiceData.slice(
            Number((this.pagination.pageNo - 1) * this.pagination.pageSize),
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
      }
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      switch (this.exportType) {
        case "company":
          this.ListData = this.CompanyRankData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "achieve":
          this.ListData = this.CompleteData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "offer":
          this.ListData = this.OfferData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "invoice":
          this.ListData = this.InvoiceData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
      }
    },
    //客户详情
    toCustomer(comId) {
      this.customerDrawerVisible = true;
      this.customId = comId;
    },
    //关闭抽屉
    handleClose() {
      this.customerDrawerVisible = false;
    },
    //关闭抽屉
    handleClose1() {
      this.dialogInfoVisible = false;
      this.real = false;
    },
    handleClick(tab, e) {
      this.msgInfo.timeType = "按月";
      this.filter.searchType = "顾问";
      this.filter.time = [
        new Date().getFullYear() + "-01" + "-01",
        new Date(Date.now() - 24 * 60 * 60 * 1000).Format("yyyy-MM") + "-01",
      ];
      if (tab.name == "frist") {
        this.permissionType();
        this.loadpermissionUserIds();
      } else {
        this.permissionTree("顾问", true, true);
      }
    },
    //平均单产类型切换
    averageChange(val) {
      if (val) {
        this.msgInfo.companyRankType = val;
        this.getYOY();
      }
    },
    // 查询
    loadInfo() {
      if (!this.filter.searchType || this.sourceIds.length == 0) {
        this.$message.error("对象必填，请选择对象！");
        this.ListData = [];
        return false;
      }
      this.getYOY();
    },
    //业绩情况图表数据
    getYOY() {
      this.msgInfo.startDate = this.filter.time[0] ? this.filter.time[0] : "";
      this.msgInfo.endDate = this.filter.time[1] ? this.filter.time[1] : "";
      this.msgInfo.sourceIds = this.sourceIds;
      this.msgInfo.sourceType = this.filter.searchType;
      this.msgInfo.show = this.filter.show;

      analyzeAchievementYOY(this.msgInfo).then((res) => {
        if (res.success) {
          let i = 0;
          res.result.dataList.forEach((element) => {
            this.chinaConfigure(element, i);
            i++;
          });
        }
      });
    },
    //业绩情况图表
    chinaConfigure(element, index) {
      let yoyData = {
        current: [], //今年此期业绩
        previous: [], //去年同期业绩
        ratio: [], //	同期比
        dataDesc: [], //数据描述
      };
      element.objectAchievements.forEach((item) => {
        yoyData.current.push(item.current); //部门名称
        yoyData.previous.push(item.previous); //业绩目标
        yoyData.ratio.push(item.ratio); //offer业绩
        yoyData.dataDesc.push(item.dataDesc); //offer业绩
      });

      let chartDom = document.getElementById("myEchart" + index);
      let myChart = echarts.init(chartDom);

      let colors = [
        "#8da7f2",
        "#b589eb",
        "#92d671",
        "#f2c561",
        "#e86666",
        "#b7bee2",
      ];
      let option = {
        title: {
          text: element.objectName,
          x: "center",
        },
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
          formatter: function (params) {
            let str = params[0].name;
            params.forEach((item, index) => {
              str =
                str +
                "<br/>" +
                item.marker +
                item.seriesName +
                " : " +
                item.value +
                (item.seriesName == "同期比" ? "%" : "");
            });
            return str;
          },
        },
        grid: {
          right: "7%",
          top: "20%",
          left: "90",
          bottom: "15%",
        },
        legend: {
          data: ["当期", "去年同期", "同期比"],
          top: "10%",
          itemWidth: 20,
          itemHeight: 12,
        },
        xAxis: [
          {
            type: "category",
            interval: 0,
            axisTick: {
              interval: 0,
            },
            axisLabel: {
              show: true,
              rotate: "0",
              textStyle: {
                fontSize: 12,
                color: "#999", //X轴文字颜色
              },
            },
            data: yoyData.dataDesc,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "金额（元）",
            min: 0,
            position: "left",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "同期比(%)",
            position: "right",
            offset: 0,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: [
          {
            name: "当期",
            type: "bar",
            stack: "one",
            barWidth: "10",
            data: yoyData.current,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "去年同期",
            type: "bar",
            stack: "two",
            barWidth: "10",
            data: yoyData.previous,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "同期比",
            type: "line",
            yAxisIndex: 1,
            data: yoyData.ratio,
            symbolSize: "5",
            symbol: "none",
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    changeType(val) {
      this.msgInfo.timeType = val;
      this.filter.searchType = "顾问";
      this.searchIdList1 = [];
      this.sourceIds = [];
      this.permissionTree("顾问", true, true);
      if (val == "总计") {
        this.loadpermissionUserIds();
      }
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
  },
};
</script>
<style lang="less" scoped>
.searchBox {
  padding: 0 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px #e5e5e5 solid;
}
.analyses {
  margin: 10px 0;
  padding: 20px 10px 0;
  background-color: #fff;
  border-radius: 4px;
  border: 1px #e5e5e5 solid;
  &:hover {
    -webkit-box-shadow: 0 3px 8px 1px #e0e0e0;
    box-shadow: 0 3px 8px 1px #e0e0e0;
  }
  #echarts1 {
    height: 445px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1366px) {
      height: 320px;
    }
  }
}
.conentChart {
  height: 350px;
  @media (max-width: 1366px) {
    height: 450px;
  }
}
.barEcharts {
  li {
    width: 22.09%;
    margin-right: 1.01%;
    padding: 1%;
    float: left;
    background-color: #fff;
    position: relative;
    border: 1px #e5e5e5 solid;
    &:last-child {
      margin-right: 0;
    }
    .more {
      right: 5%;
      position: absolute;
      top: 5%;
      color: #999;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        color: #526ecc;
      }
    }
    &:hover {
      -webkit-box-shadow: 0 3px 8px 1px #e0e0e0;
      box-shadow: 0 3px 8px 1px #e0e0e0;
    }
  }
  .bar {
    height: 280px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1366px) {
      height: 200px;
    }
  }
}
.moreBtn {
  right: 15px;
  position: absolute;
  top: 15px;
  color: #526ecc;
  font-size: 13px;
  cursor: pointer;
}
.tips {
  height: 35px;
  line-height: 35px;
  font-size: 13px;
  color: #666;
  padding: 1px 10px;
  margin-bottom: 20px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: rgba(71, 69, 69, 0.129);
  border: 1px solid #ececec;
}
</style>
<style lang="scss">
.performance {
  .el-tabs__header {
    margin: 0 0 20px;
  }
}
.dinput {
  .el-input--suffix .el-input__inner {
    border-radius: 5px 0px 0px 5px;
  }
}
.dinputr {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  .el-input--suffix .el-input__inner {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
}
</style>